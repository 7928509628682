<template>
    <div class="forgotten-password-group">
        <h3 class="title">重置密码</h3>
        <el-form class="login-form" ref="phoneForm" :model="phoneForm" :rules="phoneRules">
            <el-form-item label prop="phone">
                <div class="login-form-item login-form-input" :style="{'border-color': errObjectStatus.phone ? '#e63a2e' : '#D9D9D9'}">
                    <i class="login-form-input-icon el-icon-user"></i>
                    <el-input v-model="phoneForm.phone" placeholder="请输入手机号" class="input-box" clearable></el-input>
                </div>
            </el-form-item>
            <!-- <el-form-item label prop="numCode">
        <div class="login-form-item login-form-input">
            <i class="login-form-input-icon el-icon-postcard"></i>
            <el-input v-model="phoneForm.numCode" placeholder="请输入数字验证码" clearable></el-input>
            <img :src="codeUrl" class="form-code-img" @click="referhCode" alt="" />
        </div>
      </el-form-item> -->
            <el-form-item label prop="code">
                <div class="login-form-item login-form-input" :style="{'border-color': errObjectStatus.code ? '#e63a2e' : '#D9D9D9'}">
                    <i class="login-form-input-icon el-icon-message"></i>
                    <el-input v-model="phoneForm.code" placeholder="请输入验证码" class="input-box" clearable></el-input>
                    <div class="input-append">
                        <el-button v-if="showSendCode" class="send-code" type="text"
                            @click="useVerify">获取验证码</el-button>
                        <span v-else>{{ time }}后重新获取</span>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label prop="newPassword">
                <div class="login-form-item login-form-input" :style="{'border-color': errObjectStatus.newPassword ? '#e63a2e' : '#D9D9D9'}">
                    <i class="login-form-input-icon el-icon-lock"></i>
                    <el-input v-model="phoneForm.newPassword" placeholder="设置8至20位登录密码"
                        :type="showNewPassword ? 'text' : 'password'" class="input-box" clearable>
                        <i slot="suffix" @click="switchNewPassword">
                            <img v-if="showNewPassword" :src="showView" class="input-icon" alt="show" />
                            <img v-else :src="closeView" class="input-icon" alt="close" />
                        </i>
                    </el-input>
                </div>
            </el-form-item>
            <el-form-item label prop="affirmPassword">
                <div class="login-form-item login-form-input" :style="{'border-color': errObjectStatus.affirmPassword ? '#e63a2e' : '#D9D9D9'}">
                    <i class="login-form-input-icon el-icon-lock"></i>
                    <el-input v-model="phoneForm.affirmPassword" placeholder="请再次输入新密码" class="input-box"
                        :type="showAffirmPassword ? 'text' : 'password'" clearable>
                        <i slot="suffix" @click="switchAffirmPassword">
                            <img v-if="showAffirmPassword" :src="showView" class="input-icon" alt="show" />
                            <img v-else :src="closeView" class="input-icon" alt="close" />
                        </i>
                    </el-input>
                </div>
            </el-form-item>
            <div class="login-button" :class="{ 'login-button_loading': loginButtonLoading }" @click="handleLogin"
                :disabled="disableSubmit">
                <i v-show="loginButtonLoading" class="el-icon-loading"></i>
                <span>确定修改</span>
            </div>
        </el-form>
        <div class="has-username">
            <span class="tips">已有账号？</span>
            <el-button type="text" class="has-username-btn" @click="backLogin">登录</el-button>
        </div>
        <Verify @success="success" :mode="'pop'" :captchaType="'blockPuzzle'"
            :imgSize="{ width: '330px', height: '155px' }" ref="verify"></Verify>
    </div>
</template>
<script>
import { Encrypt } from '@/libs/secret'
import { serviceDownload } from "@/services/index.js";
import Verify from "@/components/verifition/Verify";
import { LoginModel } from "@/models/Login.js";
export default {
    name: "ForgottenPassword",
    components: {
        Verify
    },
    inject: {
        schoolId: {
            value: 'schoolId',
            default: '',
        },
        loginMain: {
            value: 'loginMain',
            default: {}
        }
    },
    props: {
        loginButtonLoading: {
            type: Boolean
        }
    },
    data() {
        const reg = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,}/;
        let checkPassword = (rule, value, callback) => {
            if (!value || !reg.test(value)) {
                return callback(
                    new Error(
                        "密码必须包含大小写字母、数字和特殊符号(@$!%*?.)，且密码长度不小于8位",
                    ),
                );
            }
            if (this.currentIndex == 0 && this.passForm.affirmPassword) {
                this.$refs.pwdForm.validateField("affirmPassword");
            } else if (this.currentIndex == 1 && this.phoneForm.affirmPassword) {
                this.$refs.phoneForm.validateField("affirmPassword");
            }
            if (this.oldPwdStatus == "10017") {
                return callback(new Error("新密码不能与旧密码一致"));
            }
            return callback();
        };
        let affirmPassword = (rule, value, callback) => {
            if (!value) {
                return callback(
                    new Error("请再次输入新密码"),
                );
            }
            let password = "";
            let confirmPwd = "";
            if (this.currentIndex == 0) {
                //通过密码
                password = this.passForm.newPassword;
                confirmPwd = this.passForm.affirmPassword;
            } else {
                //通过手机号
                password = this.phoneForm.newPassword;
                confirmPwd = this.phoneForm.affirmPassword;
            }
            if (confirmPwd && confirmPwd != password) {
                //确认密码输入框有值并且和新密码不相等
                console.log("校验确认密码2");
                return callback(new Error("两次密码输入不一致"));
            }
            return callback();
        };
        const checkPhone = (rule, value, callback) => {
            console.log("校验手机号");
            const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (value == null || String(value).trim() === "") {
                callback(new Error("请输入手机号"));
            } else if (!reg.test(value)) {
                callback(new Error("手机号格式不正确"));
            } else {
                callback();
            }
        };
        const checkOldPwd = (rule, value, callback) => {
            switch (this.oldPwdStatus) {
                case "200":
                    return callback();
                    break;
                case "10010":
                    return callback(new Error("密码错误"));
                    break;
                case "10017":
                    this.$refs.pwdForm.validateField("newPassword");
                    break;
            }
        };
        const numCode = (rule, value, callback) => {
            if (value == "") {
                return callback(new Error("请输入数字验证码"));
            } else {
                return callback();
            }
        };
        return {
            phoneForm: {
                phone: "",
                code: "",
                newPassword: "",
                affirmPassword: "",
                numCode: "",
                captchaVerification: ''
            },
            phoneRules: {
                phone: [
                    {
                        validator: checkPhone,
                        trigger: ["blur", "change"],
                    },
                ],
                newPassword: [
                    {
                        validator: checkPassword,
                        trigger: ["blur", "change"],
                    },
                ],
                affirmPassword: [
                    {
                        validator: affirmPassword,
                        trigger: ["blur", "change"],
                    },
                ],
                numCode: [
                    {
                        validator: numCode,
                        trigger: ["change", "blur"],
                    },
                ],
                code: [
                    {
                        required: true,
                        trigger: ["change", "blur"],
                        message: '请输入验证码'
                    }
                ]
            },
            codeUrl: "",
            paramDate: "",
            changeTimer: null,
            showSendCode: true,
            timer: null,
            time: 60,
            showNewPassword: false,
            showAffirmPassword: false,
            errObjectStatus: {
                phone: false,
                code: false,
                newPassword: false,
                affirmPassword: false
            }
        };
    },
    computed: {
        getSchoolId() {
            return this.schoolId();
        },
        //判断是否禁用提交按钮
        disableSubmit() {
            let o = this.passForm;
            if (this.currentIndex == 0) {
                return !o.newPassword || !o.oldPassword || !o.affirmPassword;
            }
            o = this.phoneForm;
            return !o.phone || !o.code || !o.newPassword || !o.affirmPassword
        },
        closeView() {
            return require('@/assets/images/login/close-view.png')
        },
        showView() {
            return require('@/assets/images/login/show-view.png')
        }
    },
    created() {
        this.init();
    },
    mounted() {
        console.log(this,'this')
        this.loginMain.$emit('set-bc-mounted')
    },
    destroyed() {
        this.loginMain.$emit('set-bc-unmounted')
    },
    methods: {
        init() {
            this.referhCode();
        },
        async referhCode() {
            this.paramDate = +new Date();
            this.codeUrl = await serviceDownload(this.paramDate);
        },
        serviceDownload() {

        },
        handleSubmit() { },
        changeCount() { },
        getChangePasswordCode() { },
        useVerify() {
            if (this.phoneForm.phone) {
                const loginModel = new LoginModel();
                loginModel.isReg({
                    phoneNumber: this.phoneForm.phone
                }).then(async (res) => {
                    if (res.data.data) {
                        this.$refs.verify.show()
                    } else {
                        this.$message.error('手机号未注册，，请联系管理员');
                    }
                }).catch(async () => {

                });
            } else {
                this.$message.error('请输入手机号');
            }
        },
        success(params) {
            console.log('返回的二次验证参数', params)
            this.phoneForm.captchaVerification = params.captchaVerification
            this.sendCode()
            // params 返回的二次验证参数, 和登录参数一起回传给登录接口，方便后台进行二次验证
        },
        /**
         * @Description: 发送验证码
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2024-03-28 09:48:35
         */
        async sendCode() {
            console.log("发送验证码");
            const res = await this.getPhoneCode()
            console.log(res, 'res====')
            if (res === '200') {
                clearTimeout(this.timer);
                this.time = 60;
                this.showSendCode = false;
                this.exchange();
            }
        },
        getPhoneCode() {
            let flag = false;
            this.$refs.phoneForm.validateField(['numCode', 'phone'], (valid, rule) => {
                if (valid) {
                    flag = true
                }
            })
            if (flag) {
                return
            }
            return this._fet('/school/schoolAppCommon/sendSms', {
                mobile: this.phoneForm.phone,
                smsType: 2,
                code: this.phoneForm.numCode,
                paramDate: `${this.paramDate}`
            }).then((res) => {
                if (['200', '400'].includes(res.data.data.code)) {
                    res.data.data.code == '200' && this.$message.success("提交成功，验证码已发送")
                    return res.data.data.code;
                } else {
                    this.$message.error(res.data.data.info)
                }
            })

        },
        async exchange() {
            console.log(this.time, "this.time");
            if (this.time === 1) {
                clearTimeout(this.timer);
                this.timer = null;
                this.showSendCode = true;
            } else {
                await this.delay(1000);
                this.exchange();
            }
        },
        async delay(time) {
            return new Promise((resolve) => {
                this.time--;
                this.timer = setTimeout(resolve, time);
            });
        },
        handleLogin() {
            const _this = this;
            this.$refs['phoneForm'].validate((valid, object) => {
                for(let key in this.errObjectStatus) {
                    this.errObjectStatus[key] = false;
                }
                if (valid) {
                    let form = Object.assign({}, this.phoneForm,
                        { newPassword: Encrypt(this.phoneForm.newPassword) },
                        { schoolId: this.getSchoolId }
                    )
                    console.log('通过手机号修改密码-----', form);
                    this._fet('/school/schoolUser/modifyPasswordByCode', form).then((res) => {
                        console.log(res, 'res===')
                        if (res.data.code === '200') {
                            this.$message.success('修改成功')
                            setTimeout(function () {
                                _this.backLogin();
                            }, 200)
                        } else if (res.data.code === '70019') {
                            this.$message.error('手机号未绑定，请联系管理员重置密码。')
                        } else if (res.data.code === '60002') {
                            this.$message.error(res.data.msg || '短信验证码错误')
                        } else {
                            this.$message.error('修改失败')
                        }
                    })
                } else {
                    this.$eventDispatch('loginRulesErr')
                    this.setLoginRulesError(object)
                    return false;
                }
            })
        },
        setLoginRulesError(errObject) {
            const errGroup = Object.keys(errObject);
            errGroup.forEach(key => {
                this.errObjectStatus[key] = true;
            })
        },
        backLogin() {
            this.loginMain.$emit('set-bc-unmounted')
            this.$emit("backLogin");
        },
        switchAffirmPassword() {
            this.showAffirmPassword = !this.showAffirmPassword;
        },
        switchNewPassword() {
            this.showNewPassword = !this.showNewPassword;
        }
    },
    destroyed() {
        clearTimeout(this.timer);
        this.timer = null;
    },
};
</script>
<style lang="scss">
.f-submit-btn {
    span {
        font-size: calc(18 * var(--coefficient, 1px)) !important;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff !important;
    }
}

.has-username-btn {
    span {
        font-size: calc(16 * var(--coefficient, 1px)) !important;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #3c7fff;
    }
}
</style>
<style lang="scss" scoped>
$coefficient: var(--coefficient, 1px);

.forgotten-password-group {
    background-color: #FFFFFF;
    box-sizing: border-box;

    .title {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: calc(28 * var(--coefficient, 1px));
        color: #05042a;
        line-height: calc(68 * var(--coefficient, 1px));
        margin-left: calc(40 * var(--coefficient, 1px));
    }

    .f-submit-btn {
        width: calc(400 * var(--coefficient, 1px));
        height: calc(50 * var(--coefficient, 1px));
        margin-top: calc(30 * var(--coefficient, 1px));
        border-radius: 8px;
        overflow: hidden;
        font-size: calc(20 * var(--coefficient, 1px)) !important;
        background-color: #3c7fff;
        opacity: 0.6;
        border: none;
    }

    .has-username {
        margin-top: calc(6 * var(--coefficient, 1px)) !important;
        float: right;
        padding: 0 calc(41 * var(--coefficient, 1px)) !important;

        .tips {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: calc(16 * var(--coefficient, 1px));
            color: #aaaaaa;
        }
    }

    .login-form {
        padding: 0 calc(41 * #{$coefficient});

        ::v-deep .el-form-item {
            margin-bottom: 0;

            +.el-form-item {
                margin-top: calc(34 * #{$coefficient});
            }

            .el-form-item__error {
                padding-top: calc(3 * #{$coefficient});
                font-size: calc(15 * #{$coefficient});
            }
        }

        ::v-deep .el-input {
            font-size: calc(18 * #{$coefficient});

            .el-input__inner {
                border: unset;
                padding: 0;
                height: auto;
                line-height: 1;
            }

            .el-input__suffix {
                .el-input__suffix-inner {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 100%;
                }

                .el-input__icon {
                    width: auto;
                    line-height: 1;
                    font-size: calc(16 * #{$coefficient});
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    +.el-input__icon {
                        margin-left: calc(10 * #{$coefficient});
                    }
                }

                .el-input__validateIcon {
                    display: none;
                }
            }
        }

        ::v-deep .el-input--suffix {
            .el-input__inner {
                padding-right: calc(36 * #{$coefficient});
            }
        }

        .login-form-item {
            display: flex;
            align-items: center;

            .send-code {
                padding: 0 !important;

                /deep/ span {
                    font-size: calc(18 * #{$coefficient}) !important;
                }
            }

            .input-icon {
                width: calc(21 * #{$coefficient});
                margin-top: calc(10 * #{$coefficient});
                cursor: pointer;
            }
        }

        .login-form-input {
            height: calc(46 * #{$coefficient});
            padding: 0 calc(20 * #{$coefficient}) 0;
            border: 1px solid #d9d9d9;
            border-radius: calc(8 * #{$coefficient});
            box-sizing: border-box;
            display: flex;
            align-items: center;

            .input-append {
                span {
                    width: 90px;
                    padding: 0 10px;
                    display: block;
                }
            }

            .login-form-input-icon {
                color: #c9c9c9;
                font-size: calc(22 * #{$coefficient});
                padding-right: calc(12 * #{$coefficient});
            }

            ::v-deep .el-input {

                // 解决回填后带背景色问题
                .el-input__inner:-webkit-autofill,
                .el-input__inner:-webkit-autofill:hover,
                .el-input__inner:-webkit-autofill:focus,
                .el-input__inner:-webkit-autofill:active {
                    -webkit-transition-delay: 99999s;
                    -webkit-transition: color 99999s ease-out,
                        background-color 99999s ease-out;
                }
            }
        }

        .login-form-input:hover {
            border-color: #3c7fff;
        }

        .login-form-item-code {
            width: calc(103 * #{$coefficient});
            height: calc(36 * #{$coefficient});
            object-fit: contain;
            display: inline-block;
            margin-left: calc(10 * #{$coefficient});
            cursor: pointer;
        }

        .remember-password {
            display: flex;
            justify-content: space-between;
            margin-top: calc(20 * #{$coefficient});
            line-height: 1;

            ::v-deep .el-checkbox {
                display: flex;
                flex-direction: row;
                align-items: center;

                .el-checkbox__inner {
                    width: calc(16 * #{$coefficient});
                    height: calc(16 * #{$coefficient});
                    min-width: 9px;
                    min-height: 9px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &::after {
                        top: calc(2 * #{$coefficient});
                        left: calc(5 * #{$coefficient});
                        width: calc(3 * #{$coefficient});
                        height: calc(7 * #{$coefficient});
                    }
                }

                .el-checkbox__label {
                    padding-left: calc(10 * #{$coefficient});
                    line-height: calc(19 * #{$coefficient});
                    font-size: calc(16 * #{$coefficient});
                }
            }
        }

        .login-button {
            width: 100%;
            height: calc(46 * #{$coefficient});
            background: linear-gradient(90deg, #3e7dfa 0%, #5773fa 20%, #72c3fc 100%);
            border-radius: calc(8 * #{$coefficient});
            margin-top: calc(24 * #{$coefficient});
            text-align: center;
            line-height: calc(46 * #{$coefficient});
            font-size: calc(16 * #{$coefficient});
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;

            .el-icon-loading {
                margin-right: calc(6 * #{$coefficient});
            }
        }

        .login-button_loading {
            cursor: not-allowed;
            pointer-events: none;
            filter: opacity(30%);
            -webkit-filter: opacity(30%);
        }
    }
}
</style>
